<template>
  <div>
    <h2 class="title">新しいパスワードを設定</h2>
    <el-form>
      <el-form-item label="古いパスワード">
        <el-input
          show-password
          :value="oldPass"
          placeholder="古いパスワード"
          @input="x => $emit('changeOldPassword', x)"
        />
      </el-form-item>
      <el-form-item label="新しいパスワード">
        <el-input
          show-password
          :value="newPass"
          placeholder="新しいパスワード"
          @input="x => $emit('changeNewPassword', x)"
        />
      </el-form-item>
      <el-form-item label="Pinコード">
        <el-input
          :value="pinCode"
          placeholder="PinCode"
          @input="x => $emit('changePinCode', x)"
        />
      </el-form-item>
      <el-form-item style="text-align: center">
        <el-button v-loading="loading" type="primary" @click="$emit('onSubmit')"
          >変更</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'NewPass',
  props: {
    oldPass: {
      type: String,
      required: true,
    },
    newPass: {
      type: String,
      required: true,
    },
    pinCode: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 2rem;
  text-align: center;
}
</style>

<template>
  <el-form>
    <el-form-item style="text-align: center">
      <p>設定完了</p>
    </el-form-item>
    <el-form-item style="text-align: center">
      <el-button type="primary" @click="$emit('onSubmit')">戻る</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  name: 'CompleteView',
};
</script>

<style scoped>
p {
  font-size: 2rem;
}
</style>

<template>
  <el-card>
    <rechange-pass
      v-if="state === 'INPUT_EMAIL'"
      :loading="loading.preChange"
      :address="email"
      @changeAddress="setEmail"
      @onSubmit="prePasswordChange"
    />
    <new-pass
      v-else-if="state === 'INPUT_INFO'"
      :oldPass="oldPassword"
      :newPass="newPassword"
      :pinCode="pinCode"
      :loading="loading.change"
      @changeOldPassword="setOldPassword"
      @changeNewPassword="setNewPassword"
      @changePinCode="setPinCode"
      @onSubmit="passwordChange"
    />
    <complete-view v-else-if="state === 'SUCCESS'" />
  </el-card>
</template>

<script>
import {
  RechangePass,
  NewPass,
  CompleteView,
} from '../../components/ChangePassword';
import { mapActions, mapState } from 'vuex';
import { ActionTypes } from '@/store/modules/changePassword/actions';

export default {
  components: {
    RechangePass,
    NewPass,
    CompleteView,
  },
  methods: {
    ...mapActions('changePassword', [
      ActionTypes.setEmail,
      ActionTypes.setOldPassword,
      ActionTypes.setNewPassword,
      ActionTypes.setOneTimeToken,
      ActionTypes.setPinCode,
      ActionTypes.prePasswordChange,
      ActionTypes.passwordChange,
    ]),
  },
  computed: {
    ...mapState('changePassword', {
      email: 'email',
      newPassword: 'newPassword',
      oldPassword: 'oldPassword',
      oneTimeToken: 'oneTimeToken',
      pinCode: 'pinCode',
      state: 'state',
      loading: 'loading',
    }),
  },
};
</script>

<style></style>

<template>
  <el-form>
    <el-form-item style="text-align: center">
      <p>パスワード再設定</p>
      <el-form-item style="text-align: center">
        <div>
          登録したメールアドレスを入力してください。パスワード再設定のご案内メールを送信します。
        </div>
      </el-form-item>
      <el-form-item>
        <el-input
          type="email"
          :value="address"
          placeholder="メールアドレス"
          @input="x => $emit('changeAddress', x)"
        ></el-input>
      </el-form-item>
    </el-form-item>
    <el-form-item style="text-align: center">
      <el-button v-loading="loading" type="primary" @click="$emit('onSubmit')"
        >送信</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    address: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
p {
  font-size: 2rem;
}
</style>
